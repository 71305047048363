import { Typography, Button, Form, Avatar, List, Card, Row } from 'antd';
import styled, { css, keyframes } from 'styled-components';
import { GRAY_1, WHITE, DODGERBLUE, GRAY_4, VERSATIONAL } from '@/constants/colors';
import { TagOutlined } from '@ant-design/icons';

const rotatingAnimation = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(359deg); }
`

const { Paragraph } = Typography;

const Flex = css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: start;
    gap: 0.5rem;
    color: ${GRAY_1};
`;

export const Title = styled.h2`
    margin: 0;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
`;

export const ConversationText = styled.h2`
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;

    @media screen and (max-width: 600px) {
        font-size: 1rem;
    }

    li {
        margin-top: 0.5rem;
    }
`;

export const AvatarWrapper = styled.div`
    display: flex;
    background-color: rgba(248, 178, 0, 0.2);
    border-radius: 10px;
    margin: 0 0 0 2rem;
    padding-right: 2rem;
    max-width: 912px;
`;

export const StyledAvatar = styled(Avatar)`
    position: relative;
    margin-top: -1rem;
    margin-left: -3rem;
    min-width: 128px;
`;

export const secondaryText = css`
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0;
`;

export const Description = styled.span`
    ${secondaryText}
`;

export const DescriptionFlex = styled.span`
    ${secondaryText};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div.ant-typography {
        margin: 0 !important;
    }
    &&& {
        .ant-spin-container {
            flex: 1 1 100%;
        }
        .ant-spin-nested-loading {
            flex: 1 1 100%;
            display: flex;
        }
    }
`;

export const ActionContainer = styled.div`
    ${Flex}
`;

export const ActionContainerTeamsExternal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ActionContainerPMAlly = styled.div`
    ${Flex}
    flex-direction: row;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-bottom: 1rem;
    }
`;

export const ActionContainerTeamsHigher = styled.div`
    ${Flex}
    flex-direction: row;
    min-height: 120px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-bottom: 1rem;
    }
`;


export const ActionContainerTeams = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .ant-btn {
        height: 40px;
        margin-top: 1rem;
    }

    .ant-form-item {
        width: 100%;
    }
`;

export const StyledButton = styled(Button)`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ type }) => (type === 'primary' ? WHITE : GRAY_1)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: auto;
    width: 100%;
    > svg {
        font-size: 1.5rem;
    }
    padding: 0.5rem 2rem;
`;

export const StyledButtonSmall = styled(Button)`
    font-size: 0.8rem;
    font-weight: 500;
    color: ${({ type }) => (type === 'primary' ? WHITE : GRAY_1)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    > svg {
        font-size: 1.5rem;
    }
    padding: 0.25rem 0.75rem;
    height: 50px;
`;

export const SelectPlan = styled(Button)`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ type }) => (type === 'primary' ? WHITE : GRAY_1)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: auto;
    width: 100%;
    > svg {
        font-size: 1.5rem;
    }
    padding: 0.5rem 2rem;
    margin-top: 2rem;
`;

export const DescriptionCentered = styled.p`
    ${secondaryText};
    font-weight: ${({ bold }) => (bold ? '500' : '400')};
    max-width: ${({ maxW }) => maxW || undefined};
    text-align: ${({ textAlign }) => textAlign || undefined};
    align-self: center;
    margin: 0 auto;
`;

export const LinkButton = styled(Button)`
    padding: 0;
`;

export const SignUpSection = styled.section`
    box-sizing: border-box;
    border-radius: 3px;
    background: ${WHITE};
    display: grid;
    width: 100%;
`;

export const SignUpSectionPMAlly = styled.section`
    box-sizing: border-box;
    border-radius: 3px;
    background: ${WHITE};
    padding: 1rem;
    display: flex;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12);
    height: 100%;
    max-width: 1024px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
`;

export const SignUpSectionTeams = styled.section`
    box-sizing: border-box;
    border-radius: 3px;
    background: ${WHITE};
    padding: 1.5rem 36px 36px 36px;
    display: flex;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12);
    height: 100%;
    max-width: 460px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    position: relative;
`;

export const SignUpContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    .ant-spin-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
`;

export const SignUpContainerPMAlly = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    gap: 2rem;

    .ant-spin-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
`;

export const SignUpContainerTeams = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    gap: 1rem;

    .ant-spin-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`;

export const SignUpContainerTeamsHeading = styled.h1`
    margin-bottom: 0;
    text-align: center;
`;

export const SVGContainer = styled.div`
    display: flex;
    padding: 2.25rem;
    justify-content: center;
    align-items: center;
    > svg {
        max-height: 80vh;
    }
`;

export const StyledEditableParagraph = styled(Paragraph)`
    display: inline-flex;
    color: ${DODGERBLUE};
    flex: 1;
    max-width: 30ch;
`;

export const StyledDivider = styled.div`
    color: ${GRAY_4};
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    :before,
    :after {
        content: '';
        border-top: 1px solid ${GRAY_4};
        position: absolute;
        top: 50%;
        width: 100%;
    }

    :before {
        right: 50%;
        margin-right: 1rem;
    }

    :after {
        left: 50%;
        margin-left: 1rem;
    }
`;

export const StyledForm = styled(Form)`
    ${Flex}
`;

export const StyledFormPmAlly = styled(Form)`
    ${Flex}
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const StyledFormItem = styled(Form.Item)`
    margin: 0;
`;

export const StyledFormItemPmAlly = styled(Form.Item)`
    margin: 0;
    width: calc(50% - 8px);
`;

export const StyledFormItemPmAllyFull = styled(Form.Item)`
    margin: 0;
    width: 100%;
`;

export const StyledFormItemPmAllyButton = styled(Form.Item)`
    width: 120px;
`;

export const StyledFormItemTeams = styled(Form.Item)`
    width: 180px;
`;

export const NameInputsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
`;

export const CenterColumn = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export const InProgressOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${VERSATIONAL};
    color: ${WHITE};
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const InProgressIcon = styled.div`
    svg {
        animation: ${rotatingAnimation} 2s linear infinite;
        transform-origin: center center;
    }
    
    margin-top: 1rem;
`;

export const StyledList = styled(List)`
    min-height: 20rem;

    @media screen and (max-width: 600px) {
        min-height: auto;
    }
`;

export const StyledListItem = styled(List.Item)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-width: 0 !important;
    padding: 0.4rem 0 !important;

    svg {
        margin-right: 0.5rem;
        min-width: 20px;

        path {
            fill: ${VERSATIONAL};
        }
    }
`;

export const StyledCardTop = styled.div`
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fcf5e3;
`;

export const StyledCard = styled(Card)`
    border-width: 0;
    margin-top: 3rem;

    .ant-card-head {
        background-color: ${VERSATIONAL};
        color: ${WHITE};
        text-align: center;
    }

    .ant-card-body {
        padding: 8px 0 16px 0;
    }
`;

export const ChangePlan = styled(Button)`
    position: relative;
    top: 7rem;
    margin-top: 3rem;
`;

export const StyledTagOutlined = styled(TagOutlined)`
    margin-right: 0.5rem;

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const ChangePlanWrapper = styled.div`
    text-align: center;
`;

export const PlanName = styled.div`
    font-weight: 500;
    color: ${VERSATIONAL};
    font-size: 32px;
`;

export const MostPopular = styled.div`
    position: absolute;
    top: -39px;
    left: 0;
    width: 100%;
    background-color: #4373c5;
    color: ${WHITE};
    text-align: center;
    padding: 7px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    padding-left: 1rem;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
`;

export const PlanPrice = styled.div`
    font-size: 42px;
    font-weight: 500;
    line-height: 1.2;
    display: flex;
    align-items: center;
`;

export const PlanPriceMonth = styled.div`
    font-size: 16px;
    font-weight: 400;
    display: inline-flex;
    flex-direction: column;
    padding-left: 0.25rem;
`;

export const PlanPriceMonthCurrency = styled.div`
    
`;

export const PlanPriceMonthBreakdown = styled.div`
    font-weight: bold;
`;

export const PlanExtraInfo = styled.div`
    color: #aaa;
    font-weight: 500;
    min-height: 22px;
`;

export const NoCCRequired = styled.div`
    font-size: 13px;
    text-align: center;
    font-style: italic;
    margin-top: 0.5rem;
    min-height: 20.4px;
`;

export const CurrentPlan = styled.div`
    text-align: center;
    position: absolute;
    top: -84px;
    width: 100%;
    left: 0;
`;

export const IncludedInPlan = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    min-height: 28px;
`;

export const PricingListWrapper = styled.div`
    padding: 0 2rem;
`;

export const StyledRow = styled(Row)`
    @media screen and (max-width: 600px) {
        justify-content: center;
    }

    .ant-col {
        @media screen and (max-width: 600px) {
            min-width: 250px;
        }
    }
`;

export const SpinWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
`;